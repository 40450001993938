import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const query = graphql`
	query {
		allContentfulBlogPost {
			edges {
				node {
					slug
					publishedDate(formatString: "MMMM Do, YYYY")
					featuredImage {
						gatsbyImageData(placeholder: DOMINANT_COLOR)
						description
						title
					}
					excerpt
					createdAt
					id
					title
					updatedAt(formatString: "DD-MM-YYYY")
					body {
						references {
							gatsbyImageData(placeholder: DOMINANT_COLOR)
							description
						}
						raw
					}
				}
			}
		}
		site {
			siteMetadata {
				title
				description
				siteUrl
			}
		}
	}
`;

const OkrBasics = (props) => {
	const site = props.data.site;
	const posts = props.data.allContentfulBlogPost.edges;
	const description =
		"Just getting started with OKRs? Learn the basics of Objectives & Key Results before you start using the framework.";

	return (
		<Layout>
			<Head
				title={`OKR Basics -> Learn the basics of Objectives & Key Results | ${site.siteMetadata.title}`}
				description={description}
				url={`${props.data.site.siteMetadata.siteUrl}/okr-basics/`}
			/>

			<main className="mx-auto max-w-4xl">
				<div className="my-8 sm:my-12 md:my-16">
					<h1 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-5xl md:text-6xl">
						<span className="block xl:inline">OKR Basics</span>
					</h1>
					<p className="mt-3 text-base text-slate-500 sm:mt-5 sm:text-lg md:mt-5 md:text-xl">
						{description}
					</p>
				</div>

				<div className="mb-4">
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-4">
						{posts.map((edge) => {
							return (
								<div key={edge.node.id} className="rounded bg-slate-200">
									<Link to={`/okr-basics/${edge.node.slug}/`}>
										<GatsbyImage
											className="rounded-t h-32 md:h-52"
											image={edge.node.featuredImage.gatsbyImageData}
											alt={edge.node.featuredImage.title}
										/>
										<div className="p-4">
											<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
												{edge.node.title}
											</h2>
											<p className="mt-2 text-ellipsis">{edge.node.excerpt}</p>
										</div>
									</Link>
								</div>
							);
						})}
					</div>
				</div>

				{/* {category?.body ? (
					<div
						className="max-w-4xl mx-auto mt-4 md:mt-8 px-4 sm:px-6 md:my-16 lg:my-20 lg:px-8 
        prose-p:text-lg prose-p:my-6 prose-blockquote:my-4 prose-blockquote:bg-indigo-500 prose-blockquote:text-white prose-blockquote:mb-4 prose-blockquote:rounded prose-blockquote:px-4 prose-blockquote:py-1
        prose-h2:mt-8 prose-h2:mb-4 prose-h2:text-4xl prose-h2:font-bold prose-h2:text-slate-800
        prose-h3:mb-4 prose-h3:mt-6 prose-h3:text-3xl prose-h3:font-bold prose-h3:text-slate-900
        prose-li:list-disc prose-li:ml-3 prose-li:mt-1.5 prose-li:text-thin prose-li:text-slate-800
        prose-a:text-indigo-600 prose-a:underline">
						{renderRichText(category.body)}
					</div>
				) : null} */}
			</main>
		</Layout>
	);
};

export default OkrBasics;
